<template>
  <div class="flex justify-center pt-8  px-4 pb-10 mb-20 overflow-y-auto overscroll-x-none  bg-gray-100">
    <div class="flex flex-col w-full bg-gray-100 space-y-6">    
      <!-- ข้อมูล Promptpay -->
      <event-promptpay  
        :content="content" 
        :PromptPayData="PromptPayData" 
        :savePromptpayInfo="savePromptpayInfo"
      />
    </div>
  </div>
</template>

<script setup>
import { provinceList } from "../public/data/province";
import { useEventStore } from "@/stores/event";
import dayjs from "dayjs";

import { useContentPromptpay } from '~/composables/content/v2/useContentPromptpay'
const { content } = useContentPromptpay('promptpay-info')

const useEvent = useEventStore();

//Event Init
const eventData = ref();

//Promptpay
const PromptPayData = ref({});


const init = async () => {
  const event = useEvent.event ;
  if(!event) return 
  eventData.value = event;
  PromptPayData.value = event.promptpay;  
};

onBeforeMount(async()=>await init())
//watchEffect(async() =>await init())

const savePromptpayInfo = async () => {
  const initPromptpayInfo = eventData.value.promptpay;
  const promptpay = {
    ...initPromptpayInfo,
    name: PromptPayData.value.name,
    account: PromptPayData.value.account,
  };

  await useEvent.updateEvent({ promptpay });
};


</script>

<style scoped>
/* For desktop and large screens by default */
.datepicker-width-full {
  width: 90vw !important;
}

/* For iPads (Tablets) in portrait and landscape */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
 .datepicker-width-full {
    width: 95vw !important; /* Adjust as needed */
  }
}

/* For mobile devices */
@media only screen and (max-width: 767px) {
 .datepicker-width-full {
    width: 80vw !important; /* Adjust as needed */
  }
}
</style>
